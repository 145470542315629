




















































































































































import { Component, Vue } from 'vue-property-decorator';
import SidebarLink from '@/interfaces/sidebarLink'; // eslint-disable-line
import { namespace } from 'vuex-class';
import MainTimeOption from '@/interfaces/mainTimeOption'; // eslint-disable-line
import { formatDate } from '@/utils/dateHelpers'; // eslint-disable-line

const appModule = namespace('appModule');
@Component
export default class GeneralLayout extends Vue {
  @appModule.Action
  public setAppTime!: (value: MainTimeOption) => void

  @appModule.Getter
  public currentTimeOption!: MainTimeOption

  @appModule.Getter
  public currentTimeOptions!: MainTimeOption[]

  get routeMetaName(): string {
    return this.$route.meta.name;
  }

  get formattedDate(): string|Date {
    const fromDate = new Date(
      new Date().getTime() - this.currentTimeOption.normalizedValue * 86400000,
    );
    const toDate = new Date();

    return this.currentTimeOption.normalizedValue === 0
      ? formatDate(fromDate, 'EEE, MMM do')
      : `${formatDate(fromDate, 'EEE, MMM do')} to ${formatDate(toDate, 'EEE, MMM do')}`;
  }

  public drawer: boolean = false;

  public period: MainTimeOption = {
    text: 'Today',
    value: 1,
    normalizedValue: 0,
  };

  public sidebarReactiveLinks: SidebarLink[] = [
    {
      id: 0,
      name: 'Central Logging',
      to: 'CentralLogging',
    },
    {
      id: 1,
      name: 'Internal Metrics',
      to: 'Home',
      disabled: true,
    },
    {
      id: 2,
      name: 'Bugsnag',
      to: 'Bugsnag',
    },
  ];

  public sidebarProactiveLinks: SidebarLink[] = [
    {
      id: 0,
      name: 'Automation',
      to: 'Home',
      disabled: true,
    },
    {
      id: 1,
      name: 'Unit Tests',
      to: 'Home',
      disabled: true,
    },
  ];

  updated() {
    this.period = this.currentTimeOption;
  }

  public async changeAppTime(): Promise<void> {
    await this.setAppTime(this.period);
    this.$eventBus.$emit('timeAppChanged');
  }
}
